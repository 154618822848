.signup-button-valid {
  margin-top: 30px;
  margin-right: auto;
  width: auto;
  height: 50px !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  /* background-color: #fff !important; */
}

.signup-button-valid a {
  color: #fff;
}

.signup-button-valid a:hover {
  color: #fff;
  opacity: 0.8;
}
