@import "../../../style/variables";

@font-face {
  font-family: "FilsonProBold";
  src: local("FilsonProBold"),
    url("../../../assets/fonts/FilsonProBold/font.woff") format("truetype");
  font-weight: bold;
}
.filson {
  font-family: "FilsonProBold";
  color: var(--secondary-color) !important;
}

.signup-container {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  display: flex;
  height: 100vh;
  background: url("../../../assets/background-app.png") no-repeat center center;
  background-size: cover;
}

.full {
  width: 50% !important;
}

.heading-signup {
  font-size: 17px;
  color: #1f364d;
  opacity: 0.85;
  text-align: left;
  font-weight: 300;
}
.signup-google {
  margin-left: auto;
  margin-right: auto;
}

.logo-lr {
  margin-bottom: 30px;
}
a > button.google-login {
  background-color: #fff;
  color: #0e2439;
  border-radius: 3px;
  height: 45px;
  text-align: center;
  width: 340px;
  border: 0px;
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
  transition-duration: 0.5s;
  border: 1px solid #0e2439;
}

a > button.google-login:hover {
  width: 360px;
  transition-duration: 0.5s;
}
a > button.google-login > span {
  font-size: 15px;
  font-weight: 600;
  line-height: 40px;
}
a > button.google-login > img {
  margin-bottom: 0;
  float: left;
  width: 20px;
  margin-left: 10px;
  margin-top: 10px;
}

.logo-lr > img {
  margin-right: auto;
  display: block;
}

.align-left {
  margin-right: auto;
  display: block;
}
.signup-google > a:hover {
  color: #1f364d;
  opacity: 0.85;
}

.signin-link {
  opacity: 0.85;
  font-size: 17px;
  display: inline-block;
  margin-left: 40px;
}

.signin-link > a {
  color: #1f364d;
  font-weight: 300;
}

.signin-link > a:hover {
  color: #1f364d;
  text-decoration: underline;
}

.ant-form-item-label > label {
  color: #1f364d !important;
}

.ant-form-item-label > label::after {
  content: " " !important;
}

.signup-local {
  margin-top: 30px;
  margin-bottom: 45px;
}

.signup-button.ant-btn-primary {
  display: inline-block;
  margin-top: 20px;
  width: 50%;
  height: 50px !important;
  font-size: 16px !important;
}

.ant-form-item {
  margin-bottom: 10px !important;
}

.ant-input {
  /* height: 38px !important; */
  border: 0 !important;
}

.ant-form-item-label > label::before {
  content: "" !important;
}

.signup-container-v2 {
  height: fit-content;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

.signup-container-v2 > * {
  font-family: "Poppins", sans-serif;
}

.block-left-image {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  width: 30%;
  max-width: 480px;
  // height: 100%;
  text-align: center;
  color: white;
  position: relative;
  background: url(../../../assets/signup/back-signup-1.jpg) 0% 0% / cover;
  padding: 32px;
}

.logo-block-right > a {
  position: absolute;
  top: 25px;
  right: 44px;
}

.logo-block-right {
  position: absolute;
  top: 25px;
  right: 44px;
}

.block-right-content {
  display: flex;
  flex: 1 1 0%;
  position: relative;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  background: white;
  text-align: center;
  padding: 16px 80px;
  border-top: 8px solid var(--primary-color);
}

.welcome-form {
  text-align: left;
  padding-top: 100px;
}

.trusted-by-signup {
  font-family: "FilsonProBold";
  font-size: 16px;
  margin: 0px 0px 20px 0px;
}
.tagline {
  font-family: "FilsonProBold";
  font-size: 22px;
  line-height: 150%;
  font-weight: 500;
  margin: 20px 0px 200px 0px;
  max-width: 340px;
  width: 100%;
  color: white;
  position: absolute;
  top: 70px;
  left: 30px;
  text-align: left;
}

.bottom-section {
  // background: #ff4e4e;
}
.signup-container-v2 .logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading-signup-new {
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: var(--secondary-color);
  margin-bottom: 40px;
}

.signup-local .ant-input:hover {
  border-color: var(--primary-color) !important;
  border-right-width: 1px !important;
}
.signup-local .ant-input:focus {
  border-color: var(--primary-color) !important;
  border-right-width: 1px !important;
}

.signup-local .ant-input {
  box-shadow: none;
  border: 1px solid #d9d9d96b !important;
  border-radius: 4px;
  height: 40px;
}

.password-bar div {
  height: 3px !important;
}

.new-signup {
  .promo-section {
    background-color: #fbfbfb;
    border-radius: 0px 20px 20px 0px;
    min-height: calc(100vh - 60px);
    margin: 30px 0px;
    color: var(--secondary-color);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .ant-carousel {
      text-align: center;
      padding: 30px 40px 20px 40px;
      width: 100%;
      .new-tag {
        background-color: #fabb11;
        color: var(--secondary-color);
        font-family: "Open Sans";
        font-size: 12px;
        width: fit-content;
        border-radius: 8px;
        padding: 5px 10px;
        margin: 30px auto 10px auto;
      }
      .img-switch {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 350px;
        }
      }
      .title {
        font-family: "FilsonProBold";
        font-size: 22px;
        color: var(--secondary-color);
        margin-top: 10px;
      }
      .subtitle {
        font-size: 14px;
        font-family: "Open Sans";
        font-weight: 300;
        margin: 10px 0px;
        padding: 0px 30px;
        color: var(--secondary-color);
      }
      .slick-dots.slick-dots-bottom {
        bottom: -10px;
      }
    }

    .promo-footer {
      font-size: 14px;
      font-family: "Open Sans";
      font-weight: 300;
      text-align: center;
      margin: 20px 0px 0px 0px;
      padding: 0px 0px 20px 0px;
    }
  }

  .form-section {
    padding: 30px;
    height: 100vh;
    justify-content: center;
    display: flex;
    align-items: center;
    .welcome-form {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 30px;

      .ant-form-item-label {
        line-height: 30px;
      }
      .heading-signup-new {
        margin-bottom: 20px;
        .logo {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 30px;
        }
      }
    }
    .signup-local {
      width: 480px;
      margin-top: 20px;
      margin-bottom: 25px;
      font-family: "Open Sans";
      font-weight: 300;
    }

    .signin-link {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      .question {
        color: #97a0ae;
        font-family: "Open Sans";
        font-size: 14px;
      }

      .link a {
        color: var(--secondary-color) !important;
        font-family: "Open Sans";
        font-weight: 500;
        margin-left: 8px;
        font-size: 14px;
        opacity: 1;
      }
    }
  }
  .ant-btn-primary {
    width: 100%;
    height: 40px !important;
    font-size: 16px;
    margin-top: 10px;
    text-shadow: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
  }
}

.ant-carousel .slick-dots li.slick-active button {
  background: var(--primary-color) !important;
}

.ant-carousel .slick-dots li {
  background: hsla(358, 100%, 56%, 0.116);
}

.carousel-logos {
  img {
    filter: grayscale(1);
    padding: 0px 50px;
    width: 80%;
    margin: 0 auto;
    display: flex;
  }
}
@media screen and (max-width: 991px) {
  .block-left-image {
    width: 20px;
    padding: 0px;
  }

  .promo-section {
    display: none !important;
  }

  .block-right-content {
    padding: 16px 40px;
  }

  .content-block {
    display: none;
  }
  .signup-local {
    width: 100% !important;
  }
  .new-signup.login .signup-container-v2 .welcome-form {
    width: 90%;
  }
}
