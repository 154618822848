@import "../../../style/variables";

.signin-container {
  background-color: #dbdbdb;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  display: flex;
  height: 100vh;
  background: url("../../../assets/background-app.png") no-repeat center center;

  background-size: cover;
}
.heading-signin {
  font-size: 17px;
  color: #1f364d;
  opacity: 0.85;
  text-align: left;
  font-weight: 300;
}

.block-signin {
  border-radius: 5px;
  padding: 80px 120px;
  width: 600px;
}

.signin-google {
  margin-left: auto;
  margin-right: auto;
}

a > button.google-login {
  background-color: #fff;
  color: #0e2439;
  border-radius: 3px;
  height: 45px;
  text-align: center;
  width: 300px;
  border: 0px;
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
  transition-duration: 0.5s;
  cursor: pointer;
  border: 1px solid #0e2439;
}

a > button.google-login > span {
  font-size: 15px;
  font-weight: 600;
  line-height: 40px;
}

a > button.google-login > img {
  margin-bottom: 0;
  float: left;
  width: 20px;
  margin-left: -4px;
}

.signup-link {
  margin-top: 40px;
  color: #1f364d;
  opacity: 0.85;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
}

.signup-link > span > a {
  color: #1f364d;
}

.signin-google > a:hover {
  color: #1f364d;
  opacity: 0.85;
}

a > button.google-login:hover {
  width: 320px;
  transition-duration: 0.5s;
}

.signin-local {
  margin-top: 30px;
}

.form-signup {
  width: 340px;
}

.forgot > a {
  color: #0e2439;
}

.forgot > a:hover {
  color: #0e2439;
  opacity: 0.85;
}

.new-signup.login {
  .signup-container-v2 {
    display: flex;
    align-items: center;
    justify-content: center;

    .welcome-form {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 30px;

      .heading-signup-new {
        margin-bottom: 20px;
        .logo {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 40px;
        }
      }
    }
    .ant-form-item-label {
      line-height: 30px;
    }
    .forgot {
      margin-top: 40px;
      text-align: center;
      a {
        color: #97a0ae !important;
        font-family: "Open Sans";
        font-weight: 300;
      }
    }
    .signup-local {
      width: 480px;
      margin-top: 20px;
      margin-bottom: 25px;
      font-family: "Open Sans";
      font-weight: 300;
      form {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .block-image {
    display: none;
  }
  .block-signin {
    width: 100%;
  }
  .signin-container {
    background: none;
  }
}

@media screen and (max-width: 600px) {
  .block-signin {
    padding: 60px 30px;
  }
  .signup-local {
    width: 100%;
  }
}
