.login-button {
  width: 100%;
}

.content-signin {
  margin-top: 40px;
  text-align: left;
}

.success-reset {
  text-align: left;
  font-size: 15px;
  font-weight: 300;
  display: flex;
}
