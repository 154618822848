.loader-star > img {
  width: 150px;
  margin: auto;
  display: block;
  margin-top: 200px;
}

.loader-star.white-label > img {
  width: 90px;
  margin: auto;
  display: block;
  margin-top: 250px;
}
